import type { SearchResultItem } from "types/api/search";

export type ApiCategory =
  | "token_cw"
  | "token_erc"
  | "nft_erc"
  | "nft_cw"
  | "token_ibc"
  | "token_factory"
  | "address"
  | "public_tag"
  | "transaction"
  | "block";
export type Category = ApiCategory;

export type ItemsCategoriesMap = Record<ApiCategory, Array<SearchResultItem>>;

export const searchCategories: Array<{ id: Category; title: string }> = [
  { id: "token_erc", title: "Tokens (ERC-20)" },
  { id: "token_cw", title: "Tokens (CW-20)" },
  { id: "token_ibc", title: "IBC Tokens" },
  { id: "token_factory", title: "Factory Tokens" },
  { id: "nft_erc", title: "NFTs (ERC-721 & 1155)" },
  { id: "nft_cw", title: "NFTs (CW-721)" },
  { id: "address", title: "Addresses" },
  { id: "public_tag", title: "Public tags" },
  { id: "transaction", title: "Transactions" },
  { id: "block", title: "Blocks" },
];

export function getItemCategory(item: SearchResultItem): Category | undefined {
  switch (item.type) {
    case "address":
    case "contract": {
      return "address";
    }
    case "token": {
      if (item.token_type === "ERC-20") {
        return "token_erc";
      }
      if (item.token_type === "CW-20") {
        return "token_cw";
      }
      if (item.token_type === "ERC-721" || item.token_type === "ERC-1155") {
        return "nft_erc";
      }
      if (item.token_type === "CW-721") {
        return "nft_cw";
      }
      if (item.token_type === "ICS-20") {
        return "token_ibc";
      }
      if (item.token_type === "NATIVE") {
        return "token_factory";
      }
    }
    case "block": {
      return "block";
    }
    case "label": {
      return "public_tag";
    }
    case "transaction": {
      return "transaction";
    }
  }
}
