import { getCookieChainKey } from "configs/hydration/cookies";
import isBrowser from "lib/isBrowser";
import compileValue from "lib/metadata/compileValue";
import { store } from "lib/redux/store";
import * as regexp from "lib/regexp";
import getConfig from "next/config";
import { get } from "radash";
import DevConfig from "../envs/config.dev.json";
import LocalConfig from "../envs/config.local.json";
import ProdConfig from "../envs/config.prod.json";
import { ChainKey } from "./chainConfigs";

export const replaceQuotes = (value: string | undefined) =>
  value?.replaceAll("'", '"');

/**
 * Get the value of an environment variable
 * @param path
 * @param defaultValue
 */
export const getEnvValueV2 = <R = string>(
  path: string,
  defaultValue = undefined,
): R => {
  const appEnv = getEnvValue("APP_ENV"); // this is recorded in the .env file

  let config = null;
  switch (appEnv) {
    case "dev":
      config = DevConfig;
      break;
    case "prod":
      config = ProdConfig;
      break;
    case "local":
      config = LocalConfig;
      break;
    case "local-dev":
      config = LocalConfig;
      break;
    default:
      throw new Error(`Unknown APP_ENV: ${appEnv}`);
  }

  return get<R>(config, path, defaultValue) as never;
};

export const getEnvValueV2CommonByChainKey = (path: string) => {
  const chainKey = getChainKeyConfig() as ChainKey;
  return getEnvValueV2(`common.${chainKey}.${path}`);
};

export const getEnvValue = (
  envName: string,
  templates?: Record<string, any>,
) => {
  if (isBrowser()) {
    if (process.env.NEXT_PUBLIC_APP_INSTANCE === "pw") {
      const storageValue = localStorage.getItem(envName);

      if (typeof storageValue === "string") {
        return storageValue;
      }
    }
    return compileValue(
      replaceQuotes(
        getConfig().publicRuntimeConfig[
          `NEXT_PUBLIC_${String(envName).replace("NEXT_PUBLIC_", "")}`
        ],
      ),
      templates,
    );
  }

  return compileValue(replaceQuotes(process.env[String(envName)]), templates);
};

export const getChainKeyConfig = () => {
  return store.getState().chain.chainKey || getCookieChainKey();
};

export const parseEnvJson = <DataType>(
  env: string | undefined,
): DataType | null => {
  try {
    return JSON.parse(env || "null") as DataType | null;
  } catch (error) {
    return null;
  }
};

export const getExternalAssetFilePath = (envName: string) => {
  const parsedValue = getEnvValue(envName);

  if (!parsedValue) {
    return;
  }

  return buildExternalAssetFilePath(envName, parsedValue);
};

export const buildExternalAssetFilePath = (name: string, value: string) => {
  try {
    const fileName = name
      .replace(/^NEXT_PUBLIC_/, "")
      .replace(/_URL$/, "")
      .toLowerCase();
    const url = new URL(value);
    const fileExtension = url.pathname.match(regexp.FILE_EXTENSION)?.[1];
    return `/assets/${fileName}.${fileExtension}`;
  } catch (error) {
    return;
  }
};
