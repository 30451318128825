import BigNumber from "bignumber.js";
import "lib/date/moment";
import { reduce } from "lodash";
import React from "react";

String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

String.prototype.capitalizeFirstLetter = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

if (typeof document === "undefined") {
  React.useLayoutEffect = React.useEffect;
}

Array.prototype.sum = function () {
  return reduce(
    this,
    (acc, num) => {
      return acc.plus(new BigNumber(num));
    },
    new BigNumber(0),
  );
};

const getChildValue = (item: any, args: any[]) =>
  args.reduce((temp, key) => temp[key], item);

Array.prototype.sortByBigNumber = function (
  order: "asc" | "desc",
  ...args: any[]
) {
  return this.sort((a, b) => {
    const bnA = BigNumber(getChildValue(a, args));
    const bnB = BigNumber(getChildValue(b, args));
    if (order === "desc") return bnB.comparedTo(bnA);
    return bnA.comparedTo(bnB);
  });
};

BigInt.prototype.toJSON = function () {
  return this.toString();
};
