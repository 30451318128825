import type { Route } from "nextjs-routes";

export const PAGE_TYPE_DICT: Record<Route["pathname"], string> = {
  "/": "Homepage",
  "/txs": "Sei Network Transactions Information",
  "/tx/[hash]": "View transaction %hash% on %network_title%",
  "/blocks": "Blocks",
  "/block/[height_or_hash]": "Block details",
  "/accounts": "Top Accounts by SEI balance",
  "/address/[hash]": "Address details",
  "/verified-contracts": "Verified contracts",
  "/validators": "Validators",
  "/contract-verification": "Contract verification",
  "/address/[hash]/contract-verification": "Contract verification for address",
  "/tokens": "Tokens",
  "/nfts": "NFTs",
  "/token/[...slug]": "Token details",
  // "/token/[hash]/instance/[id]":
  //   "View the details, token transfer, properties for %id% %hash% on %network_title%",
  "/stats": "Stats",
  "/api-docs": "REST API",
  "/graphiql": "GraphQL",
  "/auth/profile": "Profile",
  "/auth/profile/watchlist": "Watchlist",
  "/auth/profile/api-keys": "API keys",
  "/auth/profile/custom-abi": "Custom ABI",
  "/account/public-tags-request": "Public tags",
  "/auth/profile/private-tags": "Private tags",
  "/withdrawals": "Withdrawals",
  "/visualize/sol2uml": "Solidity UML diagram",
  "/csv-export": "Export data to CSV file",
  "/404": "404",

  // service routes, added only to make typescript happy
  "/api/media-type": "Node API: Media type",
  "/api/proxy": "Node API: Proxy",
  "/api/csrf": "Node API: CSRF token",
  "/api/healthz": "Node API: Health check",
  // @ts-ignore
  "/auth/auth0": "Auth",
  "/auth/unverified-email": "Unverified email",
};

export default function getPageType(pathname: Route["pathname"]) {
  return PAGE_TYPE_DICT[pathname] || "Unknown page";
}
