import { IChainId } from "types/base";
import { type Chain } from "viem";

export const DevNet = {
  id: 713715,
  name: "SEI Devnet",
  nativeCurrency: { name: "SEI", symbol: "SEI", decimals: 18 },
  rpcUrls: {
    default: { http: ["https://evm-rpc-arctic-1.sei-apis.com"] },
    public: { http: ["https://evm-rpc-arctic-1.sei-apis.com"] },
  },
  blockExplorers: {
    default: { name: "Seitrace", url: "https://seitrace.com" },
  },
} as const satisfies Chain;

export const TestNet = {
  id: 1328,
  name: "SEI Testnet",
  nativeCurrency: { name: "SEI", symbol: "SEI", decimals: 18 },
  rpcUrls: {
    default: { http: ["https://evm-rpc-testnet.sei-apis.com"] },
    public: { http: ["https://evm-rpc-testnet.sei-apis.com"] },
  },
  blockExplorers: {
    default: { name: "Seitrace", url: "https://seitrace.com" },
  },
} as const satisfies Chain;

export const MainNet = {
  id: 1329,
  name: "SEI",
  nativeCurrency: { name: "SEI", symbol: "SEI", decimals: 18 },
  rpcUrls: {
    default: { http: ["https://evm-rpc.sei-apis.com"] },
    public: { http: ["https://evm-rpc.sei-apis.com"] },
  },
  blockExplorers: {
    default: { name: "Seitrace", url: "https://seitrace.com" },
  },
} as const satisfies Chain;

export enum ChainKey {
  ARCTIC_1 = "arctic-1",
  ATLANTIC_2 = "atlantic-2",
  PACIFIC_1 = "pacific-1",
}

export const ChainKeys = {
  DEVNET: ChainKey.ARCTIC_1,
  PUBLIC_TESTNET: ChainKey.ATLANTIC_2,
  MAINNET: ChainKey.PACIFIC_1,
};

export type NetworkType = "mainnet" | "testnet" | "devnet";

export enum ECosmosChain {
  SEI = "sei",
  SEI_DEVNET = "seidevnet",
  SEI_TESTNET = "seitestnet2",
}

export const chainConfigs = [
  {
    chainName: "pacific-1 (mainnet)",
    chainKey: ChainKeys.MAINNET,
    chainId: 1329,
    disabled: false,
    config: MainNet,
    networkType: "mainnet",
    chainPrettyName: "Sei (mainnet)",
    cosmosChainKey: ECosmosChain.SEI,
  },
  {
    chainName: "atlantic-2 (public testnet)",
    chainKey: ChainKeys.PUBLIC_TESTNET,
    chainId: 1328,
    config: TestNet,
    networkType: "testnet",
    chainPrettyName: "Sei (testnet)",
    cosmosChainKey: ECosmosChain.SEI_TESTNET,
  },
  {
    chainName: "arctic-1 (public devnet)",
    chainKey: ChainKeys.DEVNET,
    chainId: 713715,
    config: DevNet,
    networkType: "devnet",
    chainPrettyName: "Sei (devnet)",
    cosmosChainKey: ECosmosChain.SEI_DEVNET,
  },
] as ChainConfig[];

export type ChainConfig = {
  chainName: string;
  chainPrettyName: string;
  chainKey: IChainId;
  chainId: number;
  config: Chain;
  disabled?: boolean;
  networkType: NetworkType;
  cosmosChainKey: ECosmosChain;
};
