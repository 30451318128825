import {
  Box,
  BoxProps,
  chakra,
  Image as ChakraImage,
  ChakraProps,
  ImageProps,
  Skeleton,
} from "@chakra-ui/react";
import NextImage, {
  ImageLoaderProps,
  ImageProps as NextImageProps,
} from "next/image";
import {
  forwardRef,
  ReactNode,
  RefAttributes,
  useEffect,
  useMemo,
  useState,
} from "react";

export function cdnLoader({ src, width, quality }: ImageLoaderProps): string {
  const url = new URL(`https://assets.seitrace.com${src}`);
  url.searchParams.set("format", "auto");
  url.searchParams.set("width", width.toString());
  url.searchParams.set("quality", (quality || 75).toString());
  return url.href;
}

type BaseImageProps = Omit<NextImageProps, "src" | "fill"> &
  RefAttributes<HTMLImageElement> & {
    isCDN?: boolean;
    hasWrapper?: boolean;
    wrapperProps?: BoxProps;
    src: NextImageProps["src"] | undefined | null;
    isError?: boolean;
    isLoading?: boolean;
  } & Pick<ImageProps, "fallback">;

const BaseImage = forwardRef<HTMLImageElement, BaseImageProps>(
  (
    {
      hasWrapper,
      wrapperProps,
      src,
      fallback,
      isCDN,
      isError: parentIsError,
      isLoading,
      ...props
    },
    ref,
  ) => {
    const [isError, setIsError] = useState(false);

    useEffect(() => {
      setIsError(false);
    }, [src]);

    useEffect(() => {
      if (typeof parentIsError === "boolean") setIsError(parentIsError);
    }, [parentIsError]);

    const imageContent = useMemo(() => {
      const style = {
        WebkitTransform: "translateZ(0)",
        WebkitMaskImage:
          "-webkit-radial-gradient(circle, white 100%, black 100%)",
      };

      // Boring avatars
      if (src?.toString().includes("source.boringavatars")) {
        return (
          <ChakraImage
            src={src.toString()}
            style={style}
            {...(props as any)}
            objectFit={props.objectFit || "cover"}
            ref={ref}
            onError={() => {
              setIsError(true);
            }}
          />
        );
      }

      return isError ? (
        fallback
      ) : (
        <NextImage
          fill
          objectFit="cover"
          src={src!}
          style={style}
          {...props}
          ref={ref}
          onError={() => {
            setIsError(true);
          }}
          loader={isCDN ? cdnLoader : undefined}
        ></NextImage>
      );
    }, [isError, src, isCDN, props]);

    const content = isLoading ? (
      <Skeleton
        width="full"
        height="full"
        {...props}
        objectFit="cover"
      ></Skeleton>
    ) : isError ? (
      fallback
    ) : (
      imageContent
    );

    if (hasWrapper) {
      return (
        <Box position="relative" {...wrapperProps}>
          {content}
        </Box>
      );
    }
    return content;
  },
);

export type OptimizationImageProps = BaseImageProps & Omit<ChakraProps, "fill">;

const OptimizationImage = chakra<(props: OptimizationImageProps) => ReactNode>(
  BaseImage,
  {
    baseStyle: {},
    shouldForwardProp: (prop) =>
      [
        "ref",
        "width",
        "height",
        "src",
        "alt",
        "quality",
        "placeholder",
        "blurDataURL",
        "loader ",
        "loading",
        "priority",
        "layout",
        "isCDN",
        "fill",
        "hasWrapper",
        "wrapperProps",
        "fallback",
        "isError",
        "isLoading",
        "objectFit",
      ].includes(prop),
  },
);

OptimizationImage.defaultProps = {};

export default OptimizationImage;
