import { Skeleton } from "@chakra-ui/react";
import { FT_TOKEN_TYPE_IDS, NFT_TOKEN_TYPE_IDS } from "lib/token/tokenTypes";

import IconSvg, { IconSvgProps } from "ui/shared/IconSvg";

const NFT_TYPES = [
  ...NFT_TOKEN_TYPE_IDS,
  ...NFT_TOKEN_TYPE_IDS.map((x) => x.toLowerCase()),
  "nft",
] as const;
const FT_TYPES = [
  ...FT_TOKEN_TYPE_IDS,
  ...FT_TOKEN_TYPE_IDS.map((x) => x.toLowerCase()),
  "ICS20",
  "ics20",
  "ft",
] as const;

export const TokenLogoPlaceholder = ({
  type,
  isLoading,
  ...props
}: {
  type?:
    | (typeof NFT_TYPES)[number]
    | (typeof FT_TYPES)[number]
    | Lowercase<(typeof NFT_TYPES)[number]>
    | Lowercase<(typeof FT_TYPES)[number]>;
} & Partial<IconSvgProps>) => {
  return (
    <Skeleton isLoaded={!isLoading}>
      <IconSvg
        backgroundColor="neutral.light.2"
        borderColor="neutral.light.4"
        borderWidth="0.5px"
        color="neutral.light.7"
        borderRadius="full"
        name={
          (NFT_TYPES.find((x) => x === type?.toLocaleLowerCase()) &&
            "nft_shield") ||
          (FT_TYPES.find((x) => x === type?.toLocaleLowerCase()) &&
            "ft_shield") ||
          "token-placeholder"
        }
        boxSize={4}
        flexShrink={0}
        transitionProperty="background-color,color"
        transitionDuration="normal"
        transitionTimingFunction="ease"
        {...props}
      />
    </Skeleton>
  );
};

export default TokenLogoPlaceholder;
