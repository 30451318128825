import { Box, Button, Flex, Link, Text } from "@chakra-ui/react";
import React from "react";

// import useIsMobile from "lib/hooks/useIsMobile";
import {
  clearRecentSearchKeywords,
  getRecentSearchKeywords,
  removeRecentSearchKeyword,
} from "lib/recentSearchKeywords";
// import TextAd from "ui/shared/ad/TextAd";
import IconSvg from "ui/shared/IconSvg";

type Props = {
  onClick: (kw: string) => void;
  onClear: () => void;
};

const SearchBarRecentKeywords = ({ onClick, onClear }: Props) => {
  const [keywords, setKeywords] = React.useState<Array<string>>(
    getRecentSearchKeywords(),
  );

  const handleClick = React.useCallback(
    (kw: string) => () => {
      onClick(kw);
    },
    [onClick],
  );

  const clearKeywords = React.useCallback(() => {
    clearRecentSearchKeywords();
    onClear();
  }, [onClear]);

  const removeKeyword = React.useCallback(
    (kw: string) => (e: React.SyntheticEvent) => {
      e.stopPropagation();
      const result = keywords.filter((item) => item !== kw);
      setKeywords(result);
      if (result.length === 0) {
        onClear();
      }
      removeRecentSearchKeyword(kw);
    },
    [keywords, onClear],
  );

  if (!keywords.length) {
    return <></>;
  }

  return (
    <Box flex={1} width="full">
      <Flex
        pt={4}
        pb={1}
        px={4}
        width="full"
        justifyContent="space-between"
        fontSize="sm"
      >
        <Text
          color="neutral.light.5"
          fontSize="0.8125rem"
          fontWeight={400}
          lineHeight="1rem"
        >
          Recent
        </Text>
        <Link
          onClick={clearKeywords}
          fontSize="0.875rem"
          fontWeight={400}
          lineHeight="1.25rem"
        >
          Clear all
        </Link>
      </Flex>

      {keywords.map((kw) => (
        <Flex
          key={kw}
          py={3}
          px={4}
          _hover={{
            backgroundColor: "primary.light.1",
          }}
          fontSize="sm"
          color="neutral.light.8"
          onClick={handleClick(kw)}
          alignItems="center"
          justifyContent="space-between"
          cursor="pointer"
          lineHeight="1.25rem"
          columnGap={2}
          flex={1}
        >
          {/* {kw.startsWith("0x") ? (
            <Text  isTooltipDisabled />
          ) : ( */}
          <Text isTruncated>{kw}</Text>
          {/* )} */}
          <Button
            variant="unstyled"
            borderRadius="full"
            boxSize={4}
            overflow="hidden"
            onClick={removeKeyword(kw)}
            flexShrink={0}
          >
            <IconSvg name="close" boxSize={4} color="neutral.light.5" />
          </Button>
        </Flex>
      ))}
    </Box>
  );
};

export default SearchBarRecentKeywords;
