import { Tooltip, TooltipProps } from "@chakra-ui/react";
import debounce from "lodash/debounce";
import {
  Children,
  cloneElement,
  ForwardedRef,
  forwardRef,
  ReactElement,
  ReactNode,
  Ref,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from "react";
import TruncatedProvider, { TruncatedContext } from "./TruncatedProvider";

interface Props extends TooltipProps {
  children: ReactNode;
  label: ReactNode;
}

const TruncatedTextTooltipContent = forwardRef(
  (
    { children, label, isDisabled, ...props }: Props,
    ref: ForwardedRef<HTMLDivElement | null>,
  ) => {
    const childRef = useRef<HTMLElement | null>(null);
    const { isTruncated, setIsTruncated } = useContext(TruncatedContext);
    // const isFontFaceLoaded = useFontFaceObserver([{ family: BODY_TYPEFACE }]);

    const updatedTruncateState = useCallback(() => {
      if (childRef.current) {
        const scrollWidth = childRef.current.scrollWidth;
        const clientWidth = childRef.current.clientWidth;

        if (scrollWidth > clientWidth) {
          setIsTruncated?.(true);
        } else {
          setIsTruncated?.(false);
        }
      }
    }, []);

    // FIXME: that should be useLayoutEffect, but it keeps complaining about SSR
    // let's keep it as it is until the first issue
    useEffect(() => {
      updatedTruncateState();
    }, []);

    // we want to do recalculation when isFontFaceLoaded flag is changed
    // but we don't want to create more resize event listeners
    // that's why there are separate useEffect hooks
    useEffect(() => {
      const handleResize = debounce(updatedTruncateState, 1000);
      window.addEventListener("resize", handleResize);

      return function cleanup() {
        window.removeEventListener("resize", handleResize);
      };
    }, []);

    // as for now it supports only one child
    // and it is not cleared how to manage case with two or more children
    const child = Children.only(children) as ReactElement & {
      ref?: Ref<ReactNode>;
    };
    const modifiedChildren = cloneElement(child, {
      ref: (el: HTMLDivElement) => {
        childRef.current = el;
        if (typeof ref === "function") {
          ref(el);
        } else if (ref) {
          (ref as React.MutableRefObject<HTMLDivElement>).current = el;
        }
      },
    });

    return (
      <Tooltip label={label} isDisabled={!isTruncated || isDisabled} {...props}>
        {modifiedChildren}
      </Tooltip>
    );
  },
);

const TruncatedTextTooltip = (
  {
    defaultIsTruncated,
    highPriorityIsTruncated,
    ...props
  }: Props & {
    defaultIsTruncated?: boolean;
    highPriorityIsTruncated?: boolean;
  },
  ref: ForwardedRef<HTMLDivElement | null>,
) => {
  return (
    <TruncatedProvider
      defaultIsTruncated={defaultIsTruncated}
      highPriorityIsTruncated={highPriorityIsTruncated}
    >
      <TruncatedTextTooltipContent
        ref={ref}
        {...props}
      ></TruncatedTextTooltipContent>
    </TruncatedProvider>
  );
};

export default forwardRef(TruncatedTextTooltip);
