import { getEnvValue, getEnvValueV2 } from "./utils";

const appPort = getEnvValue("NEXT_PUBLIC_APP_PORT");
const appSchema = getEnvValue("NEXT_PUBLIC_APP_PROTOCOL");
const appHost = getEnvValue("NEXT_PUBLIC_APP_HOST");
const baseUrl = [
  appSchema || "https",
  "://",
  appHost,
  appPort ? ":" + appPort : "",
]
  .filter(Boolean)
  .join("");
const isDev = getEnvValue("NODE_ENV") === "development";

const apiUrl = `${getEnvValue("NEXT_PUBLIC_API_PROTOCOL")}://${getEnvValue(
  "NEXT_PUBLIC_API_HOST",
)}:${getEnvValue("NEXT_PUBLIC_API_PORT")}`;

const authWorkspace = {
  appId: getEnvValueV2("workspace.WORKSPACE_ID") || "",
  endpoint: getEnvValueV2("workspace.WORKSPACE_ENDPOINT") || "",
  resources: [getEnvValueV2("workspace.WORKSPACE_RESOURCE") || ""],
  redirectUrl: `${baseUrl || "http://localhost:3000"}/workspace/callback`,
};

console.log({ authWorkspace, baseUrl, appPort });

// console.log("uesProxy", getEnvValue("NEXT_PUBLIC_USE_NEXT_JS_PROXY"));
const app = Object.freeze({
  isDev,
  apiUrl,
  baseUrl,
  appUrl: baseUrl,
  authWorkspace,
  host: appHost,
  port: appPort,
  protocol: appSchema,
  useProxy: getEnvValue("NEXT_PUBLIC_USE_NEXT_JS_PROXY") === "true",
});

export default app;
