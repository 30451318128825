import { Center, Flex, FlexProps } from "@chakra-ui/react";
import React, { ReactNode } from "react";
import DataFetchAlert from "./DataFetchAlert";
import Empty, { EmptyProps } from "./Empty";
import Loading from "./Loading";

type FilterProps = {
  hasActiveFilters: boolean;
  emptyFilteredText: string;
};

type Props = Partial<Omit<FlexProps, "content">> & {
  isError?: boolean;
  items?: Array<unknown>;
  emptyText?: ReactNode;
  actionBar?: React.ReactNode;
  content?: any;
  className?: string;
  filterProps?: FilterProps;
  isEmpty?: any;
  isLoading?: boolean | any;
  placeholder?: ReactNode;
  emptyProps?: EmptyProps;
};

const DataListDisplay = ({
  isError,
  // filterProps,
  // items,
  actionBar,
  content,
  children,
  isEmpty,
  isLoading,
  emptyText,
  placeholder,
  emptyProps,
  ...otherProps
}: Props) => {
  return (
    <Flex
      flexDirection="column"
      width="full"
      height="full"
      flex={1}
      // overflowY={{ base: "scroll", lg: "unset" }}
      gap={3}
      {...otherProps}
      // minHeight="30.75rem"
    >
      {actionBar}
      {isLoading ? (
        placeholder || (
          <Center width="full" height="full" flex={1}>
            <Loading></Loading>
          </Center>
        )
      ) : isError ? (
        <DataFetchAlert />
      ) : isEmpty ? (
        <Empty text={emptyText} height="30.75rem" {...emptyProps} />
      ) : (
        <>
          {content}
          {children}
        </>
      )}
    </Flex>
  );
};

export default DataListDisplay;
