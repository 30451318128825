import { AppProps } from "next/app";
import InitContexts from "ui/init/InitContexts";
import Layout from "ui/shared/layout/LayoutDefault";

import "lib/setLocale";
import { NextPage } from "next";
import { ReactNode } from "react";
import "styles/index.css";
import "../lib/global";

type Props = AppProps & { Component: NextPage };

function MyApp({ Component, pageProps }: Props) {
  const getLayout =
    Component.getLayout ??
    ((page: ReactNode) => (
      <Layout
        customContent={
          Component.getCustomContent ? Component.getCustomContent({}) : null
        }
      >
        {page}
      </Layout>
    ));
  const getHead = Component.getHead ?? (() => <></>);
  return (
    <>
      {getHead(pageProps)}
      <InitContexts pageProps={pageProps}>
        {getLayout(<Component {...pageProps} />)}
      </InitContexts>
    </>
  );
}

export default MyApp;
