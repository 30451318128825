import { Flex, Text } from "@chakra-ui/react";
import React from "react";

import type { SearchResultBlock } from "types/api/search";

import moment from "lib/date/moment";
import IconSvg from "ui/shared/IconSvg";

interface Props {
  data: SearchResultBlock;
}

const SearchBarSuggestBlock = ({ data }: Props) => {
  // const hash = (
  //   <Text
  //     variant="secondary"
  //     overflow="hidden"
  //     whiteSpace="nowrap"
  //     as={shouldHighlightHash ? "mark" : "span"}
  //     display="block"
  //   >
  //     <HashStringShortenDynamic hash={data.block_hash} isTooltipDisabled />
  //   </Text>
  // );
  const date = moment(data.timestamp).format("llll");

  return (
    <Flex alignItems="center" gap={3} overflow="hidden">
      <IconSvg name="block-purple" boxSize={4} />

      <Flex flexDirection="column" gap={1} flex={1} overflow="hidden">
        <Text width="full" isTruncated color="neutral.light.8" size="875">
          {data.block_number.toString()}
        </Text>
        <Text width="full" isTruncated color="neutral.light.6" size="8125">
          {date}
        </Text>
      </Flex>
    </Flex>
  );
};

export default React.memo(SearchBarSuggestBlock);
