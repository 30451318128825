import type { FlexProps, LinkProps } from "@chakra-ui/react";
import { Link, Skeleton } from "@chakra-ui/react";
import { omit } from "lodash";
import type { LinkProps as NextLinkProps } from "next/link";
import NextLink from "next/link";
import type { LegacyRef } from "react";
import React from "react";
import IconSvg from "./IconSvg";

const LinkInternal = (
  {
    hasIcon,
    isLoading,
    ...props
  }: LinkProps & { isLoading?: boolean; hasIcon?: boolean },
  ref: LegacyRef<HTMLAnchorElement>,
) => {
  if (isLoading) {
    return (
      <Skeleton alignItems="center" {...(props as FlexProps)}>
        {props.children}
      </Skeleton>
    );
  }

  if (!props.href) {
    return <Link {...props} ref={ref} />;
  }

  return (
    <NextLink
      href={props.href as NextLinkProps["href"]}
      passHref
      target={props.target}
      legacyBehavior
      scroll={false}
    >
      <Link
        display="inline-flex"
        alignItems="center"
        gap={2}
        {...omit(props, "children")}
        ref={ref}
      >
        {props.children}
        {hasIcon && (
          <IconSvg
            name="arrows/north-east"
            boxSize={5}
            verticalAlign="middle"
            color="inherit"
            flexShrink={0}
          />
        )}
      </Link>
    </NextLink>
  );
};

export default React.forwardRef(LinkInternal);
