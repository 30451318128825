import { alertAnatomy as parts } from "@chakra-ui/anatomy";
import type { StyleFunctionProps } from "@chakra-ui/styled-system";
import {
  createMultiStyleConfigHelpers,
  cssVar,
} from "@chakra-ui/styled-system";
import { transparentize } from "@chakra-ui/theme-tools";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const $fg = cssVar("alert-fg");
const $bg = cssVar("alert-bg");

function getBg(props: StyleFunctionProps) {
  const { theme, colorScheme: c } = props;
  const darkBg = transparentize(`${c}.200`, 0.16)(theme);
  return {
    light: `colors.${c}.100`,
    dark: darkBg,
  };
}

const baseStyle = definePartsStyle({
  container: {
    bg: $bg.reference,
    borderRadius: "md",
    px: 6,
    py: 3,
  },
  title: {
    fontWeight: "bold",
    lineHeight: "6",
    marginEnd: "2",
  },
  description: {
    lineHeight: "6",
  },
  icon: {
    color: $fg.reference,
    flexShrink: 0,
    marginEnd: "3",
    w: "5",
    h: "6",
  },
  spinner: {
    color: $fg.reference,
    flexShrink: 0,
    marginEnd: "3",
    w: "5",
    h: "5",
  },
});

const variantSubtle = definePartsStyle((props) => {
  const { colorScheme } = props;
  const bg = getBg(props);

  return {
    container: {
      [$fg.variable]:
        colorScheme === "gray"
          ? "colors.blackAlpha.800"
          : `colors.${colorScheme}.500`,
      [$bg.variable]: colorScheme === "gray" ? "colors.gray.100" : bg.light,
      _dark: {
        [$fg.variable]:
          colorScheme === "gray"
            ? "colors.whiteAlpha.800"
            : `colors.${colorScheme}.200`,
        [$bg.variable]: colorScheme === "gray" ? "colors.gray.800" : bg.dark,
      },
    },
  };
});

const variantSolid = definePartsStyle((props) => {
  const { colorScheme: c } = props;
  return {
    container: {
      [$fg.variable]: `colors.white`,
      [$bg.variable]: `colors.${c}.500`,
      _dark: {
        [$fg.variable]: `colors.gray.900`,
        [$bg.variable]: `colors.${c}.200`,
      },
      color: $fg.reference,
    },
  };
});

const variantSuccess = definePartsStyle(() => {
  return {
    container: {
      backgroundColor: "secondary.02.bg",
      background: "secondary.02.bg",
      border: 0,
      borderRadius: "0.5rem",
      display: "flex",
      alignItems: "center",
      color: "secondary.02.text",
      fontWeight: 400,
      fontSize: "0.875rem",
      lineHeight: "1.25rem",
      gap: 3,
      paddingX: 3,
      paddingY: 2,
    },
  };
});

const variantVerifyContract = definePartsStyle((props) => {
  const { colorScheme } = props;
  return {
    container: {
      display: "flex",
      alignItems: "center",
      gap: 3,
      paddingX: 3,
      paddingY: 2,
      borderRadius: "0.5rem",
      backgroundColor:
        (colorScheme === "green" && "secondary.02.bg") ||
        (colorScheme === "orange" && "secondary.01.bg") ||
        (colorScheme === "red" && "secondary.05.bg") ||
        "white",

      title: {
        textStyle: "875",
        color: (colorScheme === "green" && "secondary.02.text") || "black",
      },
      description: {
        color:
          (colorScheme === "green" && "secondary.02.text") ||
          (colorScheme === "orange" && "secondary.01.text") ||
          (colorScheme === "red" && "secondary.05.text") ||
          "neutral.light.7",
      },
    },
  };
});

const variants = {
  subtle: variantSubtle,
  solid: variantSolid,
  success: variantSuccess,
  verifyContract: variantVerifyContract,
};

const Alert = defineMultiStyleConfig({
  baseStyle,
  variants,
  defaultProps: {
    variant: "subtle",
    colorScheme: "blue",
  },
});

export default Alert;
