import { Box, HStack, Skeleton, StackProps, Text } from "@chakra-ui/react";
import { usePromotion } from "lib/zustand/promotion";
import { memo } from "react";
import IconSvg from "../shared/IconSvg";
import Tag from "../shared/chakra/Tag";

type Props = {
  //
} & StackProps;

const SearchPromotion = ({ ...props }: Props) => {
  const { brand, secondary, logo } = usePromotion((store) => store.promotion);

  return (
    <HStack
      spacing={0}
      flexShrink={0}
      alignItems="center"
      userSelect="none"
      rowGap={1}
      maxWidth="full"
      overflow="hidden"
      {...props}
    >
      <HStack spacing={2} width="fit-content">
        <IconSvg
          flexShrink={0}
          boxSize={5}
          src={logo}
          mr={2}
          rounded="full"
          overflow="hidden"
          optimizationImageProps={{ fallback: <Skeleton boxSize="full" /> }}
        />

        <Text
          as="span"
          whiteSpace="nowrap"
          textStyle="87500"
          flexShrink={0}
          mr={1}
        >
          {brand}
        </Text>
      </HStack>

      <Box overflow="hidden">
        <Text
          textStyle="875"
          flexShrink={0}
          color="neutral.light.6"
          mr={1}
          isTruncated
        >
          - {secondary}.
        </Text>
      </Box>

      <Tag
        flexShrink={0}
        paddingY="0.12rem"
        paddingX="0.375rem"
        colorScheme="gray"
      >
        Sponsored
      </Tag>
    </HStack>
  );
};

export default memo(SearchPromotion);
