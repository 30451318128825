import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

import isBrowser from "lib/isBrowser";
import { onChainChange } from "lib/redux/reducers/chain";
import { onPathnameChange } from "lib/redux/reducers/navigation";
import { useAppDispatch } from "lib/redux/store";
import { useRouter } from "next/router";
import type { Props as PageProps } from "nextjs/getServerSideProps";
import SearchBarTop from "ui/snippets/searchBar/SearchBarTop";

type Props = {
  children: React.ReactNode;
  pageProps: PageProps;
};

type HeaderProps = {
  searchElement: ReactNode;
};

const AppContext = createContext<PageProps & HeaderProps>({
  cookies: "",
  referrer: "",
  id: "",
  height_or_hash: "",
  hash: "",
  number: "",
  q: "",
  channel_id: "",
  counterparty_channel_id: "",
  searchElement: null,
});

export function AppContextProvider({ children, pageProps }: Props) {
  const dispatch = useAppDispatch();
  const router = useRouter();

  const [searchElement] = useState(
    <SearchBarTop
      isFullscreen
      searchInputProps={{
        placeholder:
          "Search by Address / Txn Hash / Block / Token  / NFT collection",
      }}
      contentProps={{
        minHeight: {
          base: "unset",
          lg: "20rem",
        },
        maxHeight: {
          base: "100dvh",
          lg: "85dvh",
        },
      }}
    />,
  );

  useEffect(() => {
    dispatch(onPathnameChange());
  }, [
    isBrowser() ? window.location.pathname : router.pathname,
    router.query.tab,
  ]);

  useEffect(() => {
    dispatch(
      onChainChange({ chainKey: router.query.chain as string | undefined }),
    );
  }, [router.query.chain]);

  return (
    <AppContext.Provider
      value={{
        ...pageProps,
        searchElement,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export function useAppContext() {
  return useContext(AppContext);
}

export const useHeaderContext = () => useContext(AppContext);
