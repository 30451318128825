import config from "configs/app";
import { useRouter } from "next/router";
import React from "react";

import { RootState, useAppSelector } from "lib/redux/store";

import type {
  NavGroupItem,
  NavItem,
  NavItemInternal,
} from "types/client/navigation-items";
import UserAvatar from "ui/shared/UserAvatar";
interface ReturnType {
  mainNavItems: Array<NavItem | NavGroupItem>;
  profileItem: NavItem;
}

export function isGroupItem(
  item: NavItem | NavGroupItem,
): item is NavGroupItem {
  return "subItems" in item;
}

export function isInternalItem(item: NavItem): item is NavItemInternal {
  return "nextRoute" in item;
}

export default function useNavItems(): ReturnType {
  const router = useRouter();
  const tokenType = useAppSelector(
    (state: RootState) => state.address.tokenType,
  );

  const pathname = router.asPath.split("?")[0];

  const devFeatureUrls = process.env.DEV_FEATURES_URL ?? ([] as string[]);

  return React.useMemo(() => {
    const apiNavItems = [
      config.features.restApiDocs.isEnabled
        ? {
            text: "REST API",
            nextRoute: { pathname: "/api-docs" as const },
            icon: "restAPI",
            isActive: pathname.includes("/api-docs"),
          }
        : null,
      config.features.graphqlApiDocs.isEnabled
        ? {
            text: "GraphQL",
            nextRoute: { pathname: "/graphiql" as const },
            icon: "graphQL",
            isActive: pathname.includes("/graphiql"),
          }
        : null,
      !config.UI.sidebar.hiddenLinks?.rpc_api && {
        text: "RPC API",
        icon: "RPC",
        url: "https://docs.seitrace.com/for-users/api/rpc-endpoints",
      },
      !config.UI.sidebar.hiddenLinks?.eth_rpc_api && {
        text: "Eth RPC API",
        icon: "RPC",
        url: " https://docs.seitrace.com/for-users/api/eth-rpc",
      },
    ].filter(Boolean) as NavItem[];

    const mainNavItems: ReturnType["mainNavItems"] = [
      {
        text: "Home",
        nextRoute: { pathname: "/" as const },
        isActive: pathname === "/",
      },
      {
        text: "Validators",
        nextRoute: { pathname: "/validators" as const },
        isActive: pathname === "/validators" || pathname.includes("/validator"),
      },
      {
        text: "Proposals",
        nextRoute: { pathname: "/proposals" as const },
        isActive: pathname === "/proposals" || pathname.includes("/proposal"),
      },
      {
        text: "Blockchain",
        isActive:
          /^(\/tx|\/block|\/accounts|\/verified-contracts|\/address|\/ibc-relayer|\/code-id)/.test(
            pathname,
          ),
        subItems: [
          {
            text: "Transactions",
            nextRoute: { pathname: "/txs" as const },
            isActive: pathname.startsWith("/tx"),
          },
          {
            text: "Blocks",
            nextRoute: { pathname: "/blocks" as const },
            isActive: pathname.startsWith("/block"),
          },
          {
            text: "IBC Relayers",
            nextRoute: { pathname: "/ibc-relayers" as const },
            isActive: pathname.startsWith("/ibc-relayer"),
          },
          {
            text: "Top Accounts",
            nextRoute: { pathname: "/accounts" as const },
            isActive: pathname.startsWith("/accounts"),
          },
        ],
        othersSubItems: [
          [
            {
              text: "EVM Verified Contracts",
              nextRoute: { pathname: "/verified-contracts" as const },
              isActive: pathname.startsWith("/verified-contracts"),
            },
            {
              text: "Native SEI Verified Contracts",
              nextRoute: { pathname: "/code-id-verification" as const },
              isActive: pathname.startsWith("/code-id-verification"),
            },
            {
              text: "Native SEI Code IDs",
              nextRoute: { pathname: "/code-ids" as const },
              isActive: pathname.startsWith("/code-ids"),
            },
          ],
        ],
      },
      {
        text: "Assets",
        isActive: pathname.startsWith("/token") || pathname.startsWith("/nfts"),
        subItems: [
          {
            text: "Tokens",
            nextRoute: { pathname: "/tokens" as const },
            isActive:
              pathname.startsWith("/tokens") ||
              pathname.startsWith("/token/ibc") ||
              pathname.startsWith("/token/factory") ||
              (pathname.startsWith("/token") &&
                tokenType !== "ERC-721" &&
                tokenType !== "ERC-1155" &&
                tokenType !== "CW-721"),
          },
          {
            text: "NFTs",
            nextRoute: { pathname: "/nfts" as const },
            isActive:
              pathname !== "/tokens" &&
              !pathname.startsWith("/token/ibc") &&
              !pathname.startsWith("/token/factory") &&
              (pathname === "/nfts" ||
                (pathname.startsWith("/token") &&
                  (tokenType === "ERC-721" ||
                    tokenType === "ERC-1155" ||
                    tokenType === "CW-721"))),
          },
        ],
      },
      {
        text: "Resources",
        isActive:
          pathname.startsWith("/stats") ||
          pathname.startsWith("/contract-verification") ||
          pathname.startsWith("/subscription") ||
          pathname.startsWith("/insights") ||
          pathname.startsWith("/parameters"),
        subItems: [
          {
            text: "Charts & stats",
            nextRoute: { pathname: "/stats" as const },
            isActive: pathname.startsWith("/stats"),
          },
          {
            text: "Verify contract",
            isEvmOnly: true,
            nextRoute: { pathname: "/contract-verification" as const },
            isActive: pathname.startsWith("/contract-verification"),
          },
          {
            text: "Parameters",
            nextRoute: { pathname: "/parameters" as const },
            isActive: pathname.startsWith("/parameters"),
          },
          {
            text: "Docs",
            url: "https://docs.seitrace.com/",
            isActive: false,
          },
        ],
        subItemsTop: [
          {
            text: "Seitrace Insights",
            nextRoute: { pathname: "/insights" as const },
            isNewAPI: true,
            isActive: pathname === "/insights",
          },
          {
            text: "Insights Docs",
            nextRoute: { pathname: "/insights-docs" as const },
            isActive: pathname === "/insights-docs",
          },
        ],
      },
    ].filter(Boolean);

    const profileItem = {
      text: "My profile",
      nextRoute: { pathname: "/auth/profile" as const },
      iconComponent: UserAvatar,
      isActive: pathname.includes("/auth/profile"),
    } as NavItem;

    const isAbleToShow = (nav: NavItem) => {
      const href = isInternalItem(nav)
        ? (nav.nextRoute.pathname as string)
        : nav.url;

      if (devFeatureUrls.includes(href)) {
        return false;
      }

      return true;
    };

    const filteredMainNavItems = mainNavItems
      .map((nav) => {
        if (isGroupItem(nav)) {
          const subItems = nav.subItems.map((subItem) => {
            return isAbleToShow(subItem) ? subItem : undefined;
          });

          const topItems = nav.subItemsTop?.map((subItem) => {
            return isAbleToShow(subItem) ? subItem : undefined;
          });

          const othersSubItems = nav.othersSubItems?.map((subItems) => {
            return subItems
              .map((subItem) => {
                return isAbleToShow(subItem) ? subItem : undefined;
              })
              .filter(Boolean);
          });

          return {
            ...nav,
            subItems: subItems.filter(Boolean),
            subItemsTop: topItems?.filter(Boolean),
            othersSubItems: othersSubItems as NavItem[][],
          };
        }

        return isAbleToShow(nav) ? nav : undefined;
      })
      .filter(Boolean) as Array<NavItem | NavGroupItem>;

    return {
      mainNavItems: filteredMainNavItems,
      apiNavItems,
      profileItem,
    };
  }, [pathname, router, tokenType]);
}
