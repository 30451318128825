import useFetchProfileInfo from "lib/hooks/useFetchProfileInfo";
import { ProfileSideBarItem } from "../types";
import { navs } from "../utils";

export const useProfileSideBarNavs = () => {
  const { data: user } = useFetchProfileInfo();

  const devFeatureUrls = process.env.DEV_FEATURES_URL ?? ([] as string[]);

  const filteredNavs = navs.filter((section) => {
    if (!user?.roles) return true;
    if (!section.userRolesForShow && !section.userRolesForHide) return true;

    const hasShowRole = section.userRolesForShow?.some((role) =>
      user.roles.includes(role),
    );

    const hasHideRole = section.userRolesForHide?.some((role) =>
      user.roles.includes(role),
    );

    return hasShowRole && !hasHideRole;
  });

  return filteredNavs
    .map((section) => {
      const items = section.items
        .map((item) => {
          if (devFeatureUrls.includes(item.href)) return undefined;
          return item;
        })
        .filter(Boolean);

      return items.length ? { ...section, items } : undefined;
    })
    .filter(Boolean) as {
    items: ProfileSideBarItem[];
    id: string;
    title: string;
    userRolesForShow?: string[];
    userRolesForHide?: string[];
  }[];
};
