import { Alert, AlertDescription, chakra } from "@chakra-ui/react";

const DataFetchAlert = ({ className }: { className?: string }) => {
  return (
    <Alert
      variant="verifyContract"
      colorScheme="red"
      width="fit-content"
      className={className}
    >
      <AlertDescription>
        Something went wrong. Try refreshing the page or come back later.
      </AlertDescription>
    </Alert>
  );
};

export default chakra(DataFetchAlert);
