import { Divider, Flex, Tab, TabList, Tabs, Text } from "@chakra-ui/react";
import type { UseQueryResult } from "@tanstack/react-query";
import React, { useEffect } from "react";
import { Element, scroller } from "react-scroll";

import type { SearchResultItem } from "types/api/search";

import type { ResourceError } from "lib/api/resources";

import type { ApiCategory, ItemsCategoriesMap } from "ui/shared/search/utils";
import { getItemCategory, searchCategories } from "ui/shared/search/utils";

import { debounce } from "lodash";
import DataListDisplay from "ui/shared/DataListDisplay";
import SearchBarSuggestItem from "./SearchBarSuggestItem";
import { canScrollY, isElementInContainer } from "./utils";

interface Props {
  query: UseQueryResult<Array<SearchResultItem>, ResourceError<unknown>>;
  onItemClick: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  containerId: string;
}

const SearchBarSuggest = ({ query, onItemClick, containerId }: Props) => {
  const categoriesRefs = React.useRef<Array<HTMLParagraphElement | null>>([]);
  const [tabIndex, setTabIndex] = React.useState(0);

  useEffect(() => {
    setTabIndex(0);
  }, [query.data]);

  const itemsGroups = React.useMemo(() => {
    if (!query.data) {
      return {};
    }
    const map: Partial<ItemsCategoriesMap> = {};
    query.data?.forEach((item) => {
      const cat = getItemCategory(item) as ApiCategory;
      if (cat) {
        if (cat in map) {
          map[cat]?.push(item);
        } else {
          map[cat] = [item];
        }
      }
    });

    return map;
  }, [query.data]);

  const resultCategories = searchCategories.filter(
    (cat) => itemsGroups[cat.id],
  );

  const handleScroll = React.useCallback(
    (elements: globalThis.Element[]) => {
      const container = document.getElementById(containerId);
      if (!container || !query.data?.length) {
        return;
      }
      const containerTop = container.getBoundingClientRect().top;
      const containerBottom = container.getBoundingClientRect().bottom;

      const currentElement = elements[tabIndex];
      const currentElementTop = currentElement.getBoundingClientRect().top;
      const currentElementBottom =
        currentElement.getBoundingClientRect().bottom;

      if (
        isElementInContainer(
          containerTop,
          containerBottom,
          currentElementTop,
          currentElementBottom,
        ) &&
        !canScrollY(container)
      ) {
        return;
      }

      for (let i = 0; i < elements.length; i++) {
        const element = elements[i];
        const elementTop = element.getBoundingClientRect().top;
        const elementBottom = element.getBoundingClientRect().bottom;

        if (
          isElementInContainer(
            containerTop,
            containerBottom,
            elementTop,
            elementBottom,
          )
        ) {
          setTabIndex(i);
          return;
        }
      }
    },
    [containerId, query.data, tabIndex],
  );

  React.useEffect(() => {
    if (!resultCategories.length) return;
    const container = document.getElementById(containerId);

    const listElementRaw = container?.querySelectorAll(
      resultCategories.map((cat) => `[id='${cat.id}']`).join(", "),
    );

    const listElement: globalThis.Element[] = [];
    listElementRaw?.forEach((element) => listElement.push(element));
    const throttledHandleScroll = debounce(
      () => handleScroll(listElement),
      200,
    );
    if (container) {
      container.addEventListener("scroll", throttledHandleScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener("scroll", throttledHandleScroll);
      }
    };
  }, [containerId, handleScroll, resultCategories]);

  React.useEffect(() => {
    categoriesRefs.current = Array(Object.keys(itemsGroups).length)
      .fill("")
      .map((_, i) => categoriesRefs.current[i] || React.createRef()) as any;
  }, [itemsGroups]);

  const scrollToCategory = React.useCallback(
    (index: number) => {
      setTabIndex(index);
      scroller.scrollTo(`cat_${index}`, {
        duration: 200,
        smooth: "easeInOutQuint",
        offset: 5,
        delay: 0,
        containerId: containerId,
      });
    },
    [containerId],
  );

  return (
    <DataListDisplay
      gap={0}
      isLoading={query.isFetching}
      isError={query.isError}
      isEmpty={!query.data || query.data.length === 0}
      flex={1}
      overflow="hidden"
      emptyText={
        <>
          Keyword not found.
          <br />
          Please check your keyword
        </>
      }
      emptyProps={{
        boxSize: "6.25rem",
        height: "100%",
        margin: "auto",
        textStyle: "875",
      }}
    >
      {resultCategories.length > 1 && (
        <Flex flexShrink={0} py={2} px={4} width="100%" gap={2}>
          <Tabs variant="solid" size="sm" index={tabIndex}>
            <TabList columnGap={3} gap={2} flexWrap="wrap">
              {resultCategories.map((cat, index) => (
                <Tab
                  key={cat.id}
                  onClick={() => {
                    scrollToCategory(index);
                  }}
                  padding="0.38rem 1rem"
                >
                  {cat.title}
                </Tab>
              ))}
            </TabList>
          </Tabs>
        </Flex>
      )}
      <Flex
        flexDirection="column"
        width="full"
        overflowY="auto"
        id={containerId}
        as={Element}
      >
        {resultCategories.map((cat, indx) => {
          return (
            <>
              <Divider variant="horizontal" />
              <Element
                style={{ display: "flex", flexDirection: "column" }}
                name={`cat_${indx}`}
                onClick={() => {
                  document.documentElement.style.overflowY = "scroll";
                }}
                key={cat.id}
                id={cat.id}
              >
                <Text
                  fontSize="0.8125rem"
                  lineHeight="1rem"
                  fontWeight={400}
                  color="neutral.light.5"
                  mt={4}
                  mx={4}
                  mb={1}
                  ref={(el) => {
                    categoriesRefs.current[indx] = el;
                  }}
                >
                  {cat.title}
                </Text>
                {cat.id &&
                  itemsGroups[cat.id]?.map((item, index) => (
                    <SearchBarSuggestItem
                      key={index}
                      data={item}
                      onClick={onItemClick}
                    />
                  ))}
              </Element>
            </>
          );
        })}
      </Flex>
    </DataListDisplay>
  );
};

export default SearchBarSuggest;
